/***
FONTS
***/
@font-face{
    font-family: "d-cs";
    src: url("assets/fonts/d-cs.otf")  
}

@font-face{
    font-family: "fg-l";
    src: url("assets/fonts/fg-l.otf")  
}

@font-face{
    font-family: "dw";
    src: url("assets/fonts/dw.otf")  
}


.fg-l{
    display: block;
    margin-top: -5px !important;
    font-family: "fg-l";
    font-size: 1.75vw;
    letter-spacing: -1px;
}

.dw{
    font-family: "dw" !important;
    /*font-size: 1.75vw;*/
    letter-spacing: -1px;
}

.serif{
    display: block;
    border: solid 2px crimson;
    padding: 40px;
    margin: 40px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 40px;
    line-height: 40px;
}

/***
GENERAL
***/

::-moz-selection { 
    color: rgb(23, 23, 23);
    background: gold;
}
  
::selection {
    color: rgb(23, 23, 23);
    background: gold;
}

h1, h2, h3{
    font-size: inherit;
    font-weight: normal;
}

body{
    background-color: rgb(23, 23, 23);
    color: crimson;

}

.flex-container{
    width: 97vw;
    height: 97vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty{
    -webkit-text-stroke: 1px crimson;
    color: rgba(0, 0, 0, 0);
}
.empty-header{
    -webkit-text-stroke: 1px rgb(23, 23, 23) !important;
    color: rgba(0, 0, 0, 0) !important;
}
a:hover.empty-header{
    -webkit-text-stroke: 0px !important;
    color: rgb(23, 23, 23) !important;
    text-decoration: none !important;
    border-bottom: none !important;
}

.title{
    font-family: "d-cs";
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    font-size: 10vw;
    line-height: 8vw;
}

.text{
    font-family: "d-cs";
    font-weight: normal;
    font-size: 8vw;
    line-height: 8vw;
    padding: 0 5vw;
    /*border: solid 4px white;*/
    /*box-shadow: inset 0px 0px 30px white;*/
}
.text.dw{
    margin: 7.5vw 0 3.5vw 0 !important;
}
.centro{
    text-align: center;
}

.arriba{
    position: relative;
    text-transform: uppercase !important;
    top: -7vw !important;
    padding: 0 2.5vw;
    font-size: 20vw !important;
    border: none;
}

.caps::first-letter{
    text-transform: capitalize !important;
}

.nada{
    display: none;
}

.giro{
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari 3-8 */
    transform: rotate(45deg);
}

/***
LINKS
***/

a:link{
    text-decoration: none;
    color: rgb(23, 23, 23);
}

a{
    border-bottom: 0px solid transparent;
    transition: .25s;
}
a.txt{
    color: crimson !important;
}
a.txt-2{
    color: transparent !important;
    -webkit-text-stroke: 2px crimson !important;
    border-color: crimson !important;
}
a.txt-3{
    color: crimson !important;
}
a.no-border,
a:link.no-border,
a:hover.no-border{
    border: none !important;
}

a:hover{
    border-bottom: 6px solid transparent;
    border-color: rgb(23, 23, 23);
    /*text-decoration: underline;*/
}
a:hover.txt{
    -webkit-text-stroke: 2px crimson !important;
    color: transparent !important;
    text-decoration: none !important;
    border-bottom: none !important;
}
a:hover.txt-2{
    -webkit-text-stroke: 0 crimson !important;
    color: crimson !important;
    text-decoration: none !important;
    border-bottom: none !important;
}
a:hover.txt-3{
    border-bottom: 16px solid transparent;
    border-color: crimson;
}

a:visited,
a:focus,
a:active{
    color: rgb(23, 23, 23);
}

/***
HEADER
***/

#header{
    font-family: "d-cs";
    width: 100%;
    background-color: crimson;
    position: fixed;
    z-index: 99999;
    font-size: 3vw;
    line-height: 0;
    opacity: 0;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;

}

.visible{
    opacity: 1 !important;
}
.visible2{
    display: block !important;
    transform: translateY(0px) !important;
}

.invisible{
    opacity: 0 !important;
}

.nav{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.nav li{
    display: inline;
    padding: 0 5px;
}

.nav li:first-child{
    padding-right: 12vw;
}
.nav li:last-child{
    right: -20px !important;
}

.nav li.dw{
    font-size: 2vw;
}

.nav2{
    display: none;
}


/***
PICS
***/
.pic{
    width: 100vw !important;
    margin: -10px 0 0 0 !important;
    padding: 0 !important;
    border-top: solid 4px crimson;
    border-bottom: solid 4px crimson;
}

.pic2{
    height: 60vh !important;
    margin: 10px !important;
    
    transition: .5s;

}
.pic2:hover{
    height: 62vh !important;
    box-shadow: black 5px 5px 50px;


}

/***
IFRAME
***/

.web{
    width: 60vw;
    height: 60vh;
    box-shadow: black 5px 5px 50px;
    border: none;
}
    
/***
FULLPAGE
***/

.fp-controlArrow.fp-prev{
    border-color: transparent crimson transparent transparent !important;
    left: 10px;
    border: none;
    width: 101px;
    height: 101px;
    background: url("assets/icons/arrow-left.svg") no-repeat;
    cursor: pointer;
}
.fp-controlArrow.fp-next{
    border-color: transparent crimson transparent transparent !important;
    right: 10px;
    border: none;
    width: 101px;
    height: 101px;
    background: url("assets/icons/arrow-right.svg") no-repeat;
    cursor: pointer;
}

#arrowDown{
    border-color: transparent crimson transparent transparent !important;
    position: fixed;
    right: 10px;
    bottom: 10px;
    border: none;
    width: 101px;
    height: 101px;
    background: url("assets/icons/arrow-down.svg") no-repeat;
    cursor: pointer;
    caret-color: transparent;
    opacity: 1;
    transition: .5s;
}

/***
MEDIA TAGS
***/

@media only screen and (max-width: 1450px) {
    .arriba{
        top: -10vw !important;
    }

}

/*iPad - horizontal*/
@media only screen and (max-width: 1050px) {
    .arriba{
        top: -20vw !important;
    }

}

/*iPad - vertical*/
@media (max-width: 768px) and (max-height: 1024px) {
    .arriba{
        top: -47vw !important;
    }

    .nav{
        font-size: 4vw;
    }
    .nav li:first-child {
        padding-right: 6vw;
        margin-left: -10vw !important;
    }

}

/*Mobile - horizontal*/
@media only screen and (max-width: 415px) {
    .arriba{
        top: -50vw !important;
        font-size: 48vw !important;
    }
    .nav{
        font-size: 8vw;
    }
    .nav li:first-child {
        padding-right: 6vw;
        margin-left: -10vw !important;
    }
    .nav li:nth-child(2),
    .nav li:nth-child(3),
    .nav li:nth-child(4),
    .nav li:nth-child(5),
    .nav li:nth-child(6){
        display: none;
    }
    .nada{
        display: block !important;
        font-size: 20vw !important;
        margin-top: -2px !important;
        padding: 0 !important;
        color: rgb(23, 23, 23);
        transition: .25s;
        caret-color: transparent;
    }
    .nada:hover{
        font-size: 30vw !important;
        cursor: pointer;
    }
    .nada:active{
        -ms-transform: rotate(45deg); /* IE 9 */
        -webkit-transform: rotate(45deg); /* Safari 3-8 */
        transform: rotate(45deg);
        cursor: pointer;
    }
    
    .nav2{
        transition: .75s;
        display: block !important;
        transform: translateY(-400px);
        width: 100vw !important;
        height: 30vh !important;
        background-color: crimson;
        z-index: 999 !important;
    }
    .nav2 li{
        margin-left: -22.5vw !important;
        text-align: center;
        line-height: 1vw;
        font-size: 7vw;
        padding: 4vw;
    }
    .serif{
        font-size: 5vw;
        line-height: 5.5vw;;
    }
    h3.dw,
    .text.dw{
        margin: 20vw 0 12.5vw 0 !important;
    }
    .web{
        width: 75vw;
    }
    #arrowDown{
        right: 25vw;
    }

}

